<template>
  <div class="mb-5">
    <maintopview />
    <div class="lg-fs-f-normal fs-f-1-125rem">
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4">BETBET {{ $t("text_introduce195") }}</p>
              <p>
                {{ $t("text_introduce240") }}
              </p>
              <p>
                {{ $t("text_introduce241") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/main/site_pic_comp1.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div class="my-bg-grey">
        <div class="py-32 md-py-5 container">
          <div class="row flex-row-reverse">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4">
                {{ $t("text_introduce242") }}
              </p>
              <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
                {{ $t("text_introduce243") }}
              </p>
              <p>
                {{ $t("text_introduce244") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/main/site_pic_comp2.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4">
                {{ $t("text_introduce245") }}
              </p>
              <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
                {{ $t("text_introduce246") }}
              </p>
              <p>
                {{ $t("text_introduce247") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/main/site_pic_comp3.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div class="my-bg-grey py-32 md-py-5">
        <div class="container">
          <div class="row  flex-row-reverse">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_introduce248") }}
              </p>
              <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
                {{ $t("text_introduce249") }}
              </p>
              <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_introduce250") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/main/site_pic_comp4.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_introduce251") }}
              </p>
              <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
                {{ $t("text_introduce252") }}
              </p>
              <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_introduce253") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/main/site_pic_comp5.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div class="my-bg-grey">
        <div class="py-32 md-py-5 container">
          <div class="row flex-row-reverse">
            <div class="col-12">
              <table
                class="table table-hover"
                style="--bs-table-bg: var(--bg-grey)"
              >
                <thead>
                  <tr>
                    <th scope="col">{{ $t("text_introduce223") }}</th>
                    <th scope="col">Betnet {{ $t("text_introduce254") }}</th>
                    <th scope="col">Betnet {{ $t("text_introduce255") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{{ $t("text_introduce256") }}</th>
                    <td>
                      {{ $t("text_introduce257") }}<br />{{
                        $t("text_introduce258")
                      }}
                    </td>
                    <td>
                      {{ $t("text_introduce259") }}<br />{{
                        $t("text_introduce260")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("text_introduce261") }}</th>
                    <td>
                      {{ $t("text_introduce262") }}<br />{{
                        $t("text_introduce263")
                      }}
                    </td>
                    <td>
                      {{ $t("text_introduce264") }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("text_introduce265") }}</th>
                    <td>{{ $t("text_introduce266") }}<br />{{ $t("text_introduce267") }}<br />{{ $t("text_introduce268") }}</td>
                    <td>{{ $t("text_introduce269") }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("text_introduce270") }}</th>
                    <td>{{ $t("text_introduce271") }}<br />{{ $t("text_introduce272") }}<br />{{ $t("text_introduce273") }}</td>
                    <td>{{ $t("text_introduce274") }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("text_introduce275") }}</th>
                    <td>{{ $t("text_introduce276") }}<br />{{ $t("text_introduce277") }}</td>
                    <td>{{ $t("text_introduce278") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-lg-3">
      <footerview class="mt-5" />
    </div>
  </div>
</template>

<script>
import maintopview from "./main_top.vue";
import footerview from "./footer.vue";
export default {
  name: "company",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: { maintopview, footerview },
  computed: {},
  methods: {},
  watch: {},
};
</script>
